import { curriculum } from '../data/curriculum.yml'

const mapCurriculum = (nodes) => {
  const allLectures = mapLectures(nodes)

  return {
    weeks: curriculum.weeks.map((week) => ({
      ...week,
      sections: mapSections(week.sections, allLectures),
    })),
  }
}

const mapSections = (sections, allLectures) => {
  return sections.map((section) => mapSection(section, allLectures))
}

const mapSection = (section, allLectures) => {
  const lectures = section.lectures.map((lecture) => {
    if (!allLectures[lecture]) {
      throw new Error(
        'The lecture with slug ' +
          lecture +
          ' could not be found. Make sure that the file where it should be has a valid frontmatter with the correct slug'
      )
    }
    return allLectures[lecture]
  })

  return { name: section.name, lectures }
}

const mapLectures = (nodes) => {
  return nodes.reduce(
    (lectures, node) => ({
      ...lectures,
      [node.frontmatter.slug]: {
        slug: node.frontmatter.slug,
        title: node.frontmatter.title,
        type: node.frontmatter.type,
        timeToRead: node.timeToRead,
      },
    }),
    {}
  )
}

export default mapCurriculum
