import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Week from '@components/Week'

const useStyles = makeStyles((theme) => ({
  curriculumItems: {
    borderColor: theme.palette.background.dark,
    borderTop: '3px dashed',
  },
}))

function shouldSkipAvailabilityDates() {
  if (typeof window === 'undefined') {
    return false
  }

  const href = window.location.href
  return (
    href.indexOf('localhost:8000') !== -1 ||
    href.indexOf('glo4002-staging') !== -1
  )
}

const Curriculum = ({ curriculum }) => {
  const styles = useStyles()

  return curriculum.weeks.map((week, i) => {
    const skipAvailability = shouldSkipAvailabilityDates()
    const isCurrentlyUnavailable =
      week.available_on && new Date(week.available_on) > new Date()

    if ((!skipAvailability && isCurrentlyUnavailable) || week.archived) {
      return <></>
    }

    return (
      <div key={`week-${i}`} className={styles.curriculumItems}>
        <Week
          number={i}
          sections={week.sections}
          tagline={week.tagline}
          isCurrentlyUnavailable={isCurrentlyUnavailable}
        />
      </div>
    )
  })
}

export default Curriculum
