import React from 'react'

import Typography from '@material-ui/core/Typography'

import Lecture from '@components/Lecture'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  section: {
    margin: theme.spacing(4, 0),
    marginTop: 0,
  },
  sectionName: {
    backgroundColor: theme.palette.background.dark,
    padding: theme.spacing(1.5),
    margin: 0,
  },
}))

const Section = ({ name, lectures = [] }) => {
  const classes = useStyles()

  return (
    <div className={classes.section}>
      <Typography variant="h3" className={classes.sectionName}>
        {name}
      </Typography>

      {lectures.map((lecture) => (
        <Lecture
          key={lecture.slug}
          id={lecture.slug}
          title={lecture.title}
          type={lecture.type}
          path={`/lectures/${lecture.slug}`}
        />
      ))}
    </div>
  )
}

export default Section
