import React from 'react'

import Icon from '@material-ui/core/Icon'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  lectureIcon: {
    marginRight: theme.spacing(1),
  },
}))

const LectureIcon = ({ type }) => {
  const styles = useStyles()

  const mapIcon = (type) => {
    const icons = {
      video: 'movie_filter',
      exercise: 'fitness_center',
      reading: 'menu_book',
      homework: 'fact_check',
      text: 'description',
    }

    return icons[type]
  }

  return (
    <Icon fontSize="large" className={styles.lectureIcon}>
      {mapIcon(type)}
    </Icon>
  )
}

export default LectureIcon
