import React from 'react'

import { Link } from 'gatsby-theme-material-ui'
import Typography from '@material-ui/core/Typography'

import ProgressIcon from '@components/ProgressIcon'
import LectureIcon from '@components/LectureIcon'

import useProgressTracker from '@hooks/progressTracker'

import { grey } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  lecture: {
    display: 'flex',
  },
  lectureName: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: grey[100],
    padding: theme.spacing(1),
    margin: '1px',
    width: '100%',
    color: theme.palette.text.secondary,
    verticalAlign: 'middle',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.dark,
    },
  },
  lectureName__text: {
    flexGrow: 1,
  },
  lectureIcon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(0, 5),
  },
}))

const Lecture = ({ id, title, path, type }) => {
  const { progress } = useProgressTracker()

  const classes = useStyles()

  return (
    <div className={classes.lecture}>
      <Link className={classes.lectureName} to={path}>
        {type && <LectureIcon type={type} />}
        <Typography className={classes.lectureName__text} variant="body2">
          {title}
        </Typography>
        <ProgressIcon completed={progress[id]} />
      </Link>
    </div>
  )
}

export default Lecture
