import React from 'react'

import Icon from '@material-ui/core/Icon'

import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  progressIcon: {
    display: 'block',
  },
  completed: {
    color: theme.palette.secondary.dark,
  },
}))

const ProgressIcon = ({ completed }) => {
  const styles = useStyles()

  const icon = completed ? 'check_circle' : 'radio_button_unchecked'

  return (
    <Icon
      className={clsx(styles.progressIcon, {
        [styles.completed]: completed,
      })}
    >
      {icon}
    </Icon>
  )
}

export default ProgressIcon
