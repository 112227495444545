import React from 'react'
import { graphql } from 'gatsby'

import Typography from '@material-ui/core/Typography'

import Layout from '@components/layout'
import SEO from '@components/seo'
import Page from '@components/Page'

import Curriculum from '@components/curriculum'
import ProgressIcon from '@components/ProgressIcon'

import Routes from '@root/routes'

import { makeStyles } from '@material-ui/core/styles'

import mapCurriculum from '../mappers/curriculum'

const useStyles = makeStyles((theme) => ({
  note: {
    marginBottom: theme.spacing(2),
    fontStyle: 'italic',
  },
}))

const CurriculumPage = ({ data }) => {
  const { nodes } = data.allMdx
  const curriculum = mapCurriculum(nodes)
  const styles = useStyles()

  return (
    <Layout breadcrumbs={[{ url: Routes.CURRICULUM, label: 'Curriculum' }]}>
      <SEO title="Agenda" />

      <Page>
        <Typography variant="h1">Curriculum du cours</Typography>
        <Typography className={styles.note} variant="body1">
          Les avancements <ProgressIcon completed /> du cours sont propres à ce
          navigateur et ne se refléteront pas sur d'autres appareils
        </Typography>

        <Curriculum curriculum={curriculum} />
      </Page>
    </Layout>
  )
}

export default CurriculumPage

export const query = graphql`
  query {
    allMdx {
      nodes {
        frontmatter {
          slug
          title
          type
        }
        timeToRead
      }
    }
  }
`
