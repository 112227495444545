import React from 'react'

import Typography from '@material-ui/core/Typography'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Section from '@components/Section'

import { makeStyles, withStyles } from '@material-ui/core/styles'

const Accordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'transparent',
    borderBottom: 'none',
    marginBottom: 0,
    padding: '1.5rem 0',
  },
  content: {
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: 0,
    '&$expanded': {
      margin: 0,
      backgroundColor: 'transparent',
    },
  },
  expanded: {},
  focused: { backgroundColor: 'transparent !important' },
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2),
    display: 'flex',
    flexDirection: 'column',
  },
}))(MuiAccordionDetails)

const SummaryItem = withStyles((theme) => ({
  root: {
    margin: 0,
  },
}))(Typography)

const useStyles = makeStyles((theme) => ({
  tagline: {
    marginLeft: '2rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: '0.5rem',
      width: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      wordBreak: 'break-word',
    },
  },
}))

const Week = ({
  number,
  tagline,
  sections = [],
  isCurrentlyUnavailable = false,
}) => {
  const styles = useStyles()

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <SummaryItem variant="h2">
          {isCurrentlyUnavailable && (
            <Typography variant="inherit" color="primary">
              [Non-Publié]&nbsp;&nbsp;
            </Typography>
          )}
          Semaine {number}
        </SummaryItem>
        <SummaryItem variant="subtitle2" className={styles.tagline}>
          {tagline}
        </SummaryItem>
      </AccordionSummary>
      <AccordionDetails>
        {sections.map((section) => (
          <Section
            key={section.name}
            name={section.name}
            lectures={section.lectures}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  )
}

export default Week
